import React from 'react'

class mediaContact extends React.Component {
  render() {
    return (
      <div className="media-contact mb-3 mt-md-3 mt-lg-0">
        <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-2">{this.props.title}</h2>
          <p className="font-alt font-w-400 mb-0 text-medium">
            {this.props.name}
            <br />
            <a href={"mailto:" + this.props.email} className="effect">{this.props.email}</a>
            <br />
            {this.props.telephone}
          </p>
        </div>
        )
    }
}

export default mediaContact